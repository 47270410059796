<template>
  <div
    id="dp_header_mobile"
    class="header-mobile align-items-center"
    :class="headerClasses"
  >
    <!--begin::Logo-->
    <a href="/">
      <Logo />
    </a>
    <!--end::Logo-->
    <!--begin::Toolbar-->
    <div class="d-flex align-items-center">
      <!--begin::Aside Mobile Toggle-->
      <button
        class="btn p-0 burger-icon burger-icon-left"
        id="dp_aside_mobile_toggle"
      >
        <span></span>
      </button>
      <!--end::Aside Mobile Toggle-->
      <!--begin::Topbar Mobile Toggle-->
      <button
        class="btn btn-hover-text-primary p-0 ml-2"
        id="dp_header_mobile_topbar_toggle"
        ref="dp_header_mobile_topbar_toggle"
      >
        <span class="svg-icon svg-icon-xl">
          <!--begin::Svg Icon | path:svg/icons/General/User.svg-->
          <inline-svg class="svg-icon" src="media/svg/icons/General/User.svg" />
          <!--end::Svg Icon-->
        </span>
      </button>
      <!--end::Topbar Mobile Toggle-->
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DPLayoutHeaderTopbar from '@/assets/js/layout/base/header-topbar.js';

export default {
  name: 'DPHeaderMobile',
  mounted() {
    // Init Header Topbar For Mobile Mode
    setTimeout(() => {
      DPLayoutHeaderTopbar.init(this.$refs['dp_header_mobile_topbar_toggle']);
    }, 100);
  },
  computed: {
    ...mapGetters(['getClasses']),

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses('header_mobile');
      if (typeof classes !== 'undefined') {
        return classes.join(' ');
      }
      return null;
    },
  },
  components: {
    Logo: () => import('@/components/global/Logo'),
  },
};
</script>
